export const environment = {
  firebase: {
    projectId: 'louter-bestelapp',
    appId: '1:287201057784:web:0f9c0e621db4d76637d40d',
    storageBucket: 'louter-bestelapp.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyAQkjyTK0D_9GNJTiGcuwmxC86O6mG-l0M',
    authDomain: 'louter-bestelapp.firebaseapp.com',
    messagingSenderId: '287201057784',
  },
  production: true,
  appVersion: 4.1
};
