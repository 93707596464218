import { Injectable } from '@angular/core';
import { AuthService } from './authentication.service';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private auth: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authorize()
  }

  authorize(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.auth.getUser().then(userData => {
        if (userData && userData['activated'] == 2) {
          resolve(true)
        } else if (userData && userData['activated'] !== 2) {
          this.router.navigate(['/warning/notActivated']);
          resolve(false);
        } else {
          this.router.navigate(['/login']);
          resolve(false);
        }
      }, (error) => {
        reject(error);
      })
    })
  }
}
