import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from "./services/authguard.service";


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/chat-overview/chat-overview.module').then( m => m.ChatOverviewPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule),
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'order',
    loadChildren: () => import('./pages/order/order.module').then( m => m.OrderPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'order/:id',
    loadChildren: () => import('./pages/order/order.module').then( m => m.OrderPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'confirm-order',
    loadChildren: () => import('./pages/confirm-order/confirm-order.module').then( m => m.ConfirmOrderPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'chat-overview',
    loadChildren: () => import('./pages/chat-overview/chat-overview.module').then( m => m.ChatOverviewPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'user-overview',
    loadChildren: () => import('./pages/user-overview/user-overview.module').then( m => m.UserOverviewPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'warning/:reason',
    loadChildren: () => import('./pages/warning/warning.module').then( m => m.WarningPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  providers: [

  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
