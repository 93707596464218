import { Component } from '@angular/core';
import { StatusBar, Style } from "@capacitor/status-bar";
import { AuthService } from "./services/authentication.service";
import { Router } from "@angular/router";
import { FcmService } from "./services/fcm.service";
import { environment } from "../environments/environment.prod";
import { AlertController } from "@ionic/angular";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private router: Router,
    private authService: AuthService,
    private fcmService: FcmService,
    private alertController: AlertController
  ) {
    this.initializeApp();
  }

  /**
   * Initializes the app by checking whether the user is logged in and tries to set the Android statusbar to white.
   */
  async initializeApp() {
    this.authService.checkAppVersion().subscribe(data => {
      if(data.data()['versionNumber'] > environment.appVersion) {
        this.presentVersionPopup();
      }
    });

    this.authService.getUser().then(userData => {
      if (userData && userData['activated'] == 2) {
        this.authService.registerToken(userData['uid']);
      }
    });

    this.fcmService.initPush();

    try {
      await StatusBar.setBackgroundColor({color: "#FFFFFF"});
      await StatusBar.setStyle({ style: Style.Light });
    } catch (err) {
      console.log('StatusBar not implemented on web, working as intended.')
    }
  }

  async presentVersionPopup() {
    const alert = await this.alertController.create({
      cssClass: 'alert-class',
      header: 'Deze versie van de Louter Hub is verouderd.',
      message: '<p>Hierdoor werkt de app mogelijk niet goed of mis je belangrijke functies.</p><p>Update de app in de App Store of Google Play Store.</p>',
      buttons: [
        {
          text: 'Melding sluiten',
          role: 'cancel',
          cssClass: 'secondary'
        }
      ]
    });

    await alert.present();
  }
}
