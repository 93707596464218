import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom, Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FcmService } from "./fcm.service";
import firebase from 'firebase/compat/app'

export interface User {
  phonenumber: string;
  uid: string;
  email: string;
  companyID: number;
  displayname: string;
  companyName: string;
  companyArray: ReadonlyMap<number, string>;
  image?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<any>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private fcmService: FcmService
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<any>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  /**
   * Creates a new user based on details filled in on registration form and returns newly generated user's uid.
   *
   * @param displayName
   * @param email
   * @param password
   * @param phoneInput
   * @return uid
   */
  async signUp({ displayName, email, password, phoneInput }): Promise<any> {
    const credential = await this.afAuth.createUserWithEmailAndPassword(
      email,
      password
    );

    const uid = credential.user.uid;
    const userEmail = credential.user.email;

    await this.afs.doc(`users/${uid}`).set({
      uid,
      email: userEmail,
      companyID: '',
      displayname: displayName,
      phonenumber: phoneInput,
      activated: 0
    });

    return uid;
  }

  /**
   * Attempts to log in user with provided email and password.
   *
   * @param email
   * @param password
   * @return signed in user.
   */
  public signIn({ email, password }) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  /**
   * Signs out the user by removing the cookie containing the user's available products and returns them to the login screen.
   */
  public async signOut() {
    localStorage.removeItem('orderProducts');

    let user = await this.getUser();
    await this.removeToken(user.uid);

    return this.afAuth.signOut().then(() => {
      this.router.navigate(['/login']);
    });
  }

  /**
   * Returns the current user's information from this.user variable.
   *
   * @return all the information of user's firebase document.
   */
  public getUser() {
    return firstValueFrom(this.user$.pipe(first()));
  }

  /**
   * Updates user's details by setting new values inside their firebase document.
   *
   * @param uid
   * @param displayname
   * @param companyID
   * @param companyName
   */
  public async updateUserDetails(uid, displayname, companyID, companyName, phonenumber) {
    const docRef = this.afs.doc(`users/${uid}`);
    await docRef.update({ displayname: displayname, companyID: parseInt(companyID), companyName, phonenumber });
  }

  /**
   * Retrieves user details by searching for their email in the user document on firebase.
   *
   * @param email
   * @return observable containing user details
   */
  public async getUserByEmail(email) {
    return this.afs.collection('users', ref => ref
      .where('email', '==', email)).valueChanges(
    ) as Observable<User[]>;
  }

  /**
   * Updates user's profile picture by setting a new base64 inside the picture field of their firebase document.
   *
   * @param uid
   * @param base64
   */
  public async updateUserImage(uid, base64) {
    const docRef = this.afs.doc(`users/${uid}`);
    await docRef.update({ image: base64 });
  }

  /**
   * Retrieves user details in the user document on firebase.
   *
   * @param uid
   * @return document containing user details
   */
  public getUserDetails(uid) {
    const docRef = this.afs.doc(`users/${uid}`);
    return docRef.get();
  }

  /**
   * Retrieves company details in the company document on firebase.
   *
   * @param cid
   * @return document containing company information
   */
  public getCompanyDetails(cid) {
    const docRef = this.afs.doc(`companies/${cid}`);
    return docRef.get();
  }

  /**
   * Sends password reset email to provided email.
   *
   * @param email
   * @return Error upon failure.
   */
  public async sendPasswordResetEmail(email) {
    this.afAuth.sendPasswordResetEmail(email).then(() => {
        console.log("Email address found, password reset email sent successfully.");
      },
      err => {
        return err
      });
  }

  /**
   * Sets the activated field of the provided user to 1 to disable the account.
   *
   * @param uid
   */
  public async disableAccount(uid) {
    const docRef = this.afs.doc(`users/${uid}`);
    await docRef.update({ activated: 1 });

    await this.signOut();
  }

  /**
   * Adds device token of current device to the deviceArray field in the user's Firebase document if it's not already in there.
   *
   * @param uid
   */
  public async registerToken(uid) {
    if (this.fcmService.deviceToken != undefined) {
      const docRef = this.afs.doc(`users/${uid}`);
      await docRef.update({deviceArray: firebase.firestore.FieldValue.arrayUnion(this.fcmService.deviceToken)});
    }
  }

  /**
   * Removes device token of current device to the deviceArray field in the user's Firebase document if it's in there.
   *
   * @param uid
   */
  public async removeToken(uid) {
    if (this.fcmService.deviceToken != undefined) {
      const docRef = this.afs.doc(`users/${uid}`);
      await docRef.update({deviceArray: firebase.firestore.FieldValue.arrayRemove(this.fcmService.deviceToken)});
    }
  }

  /**
   * Checks version of app in Firebase to check if app is up-to-date.
   *
   */
  public checkAppVersion() {
    const docRef = this.afs.doc(`appVersion/HaYostcA76rfeoQMuQsT`);
    return docRef.get();
  }
}
